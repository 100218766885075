import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136')
];

export const server_loads = [0,14,15,2,24,23];

export const dictionary = {
		"/(root)": [~65,[12]],
		"/(root)/activities/[id]": [~66,[12]],
		"/(admin)/admin": [~48,[9]],
		"/(admin)/admin/events": [~49,[9]],
		"/(admin)/admin/events/check-in/[eventName]/[year]": [~53,[9]],
		"/(admin)/admin/events/create": [~54,[9,11]],
		"/(admin)/admin/events/[id]": [~50,[9]],
		"/(admin)/admin/events/[id]/edit": [~51,[9,10]],
		"/(admin)/admin/events/[id]/orders": [~52,[9]],
		"/(root)/blog": [67,[12,13]],
		"/(root)/blog/[article]": [68,[12,13]],
		"/(root)/call-for-speakers": [69,[12]],
		"/(root)/call-for-speakers/[event]/[year]": [~70,[12]],
		"/(redirects)/camp": [~56],
		"/(redirects)/cfp": [~57],
		"/(redirects)/family": [~58],
		"/(root)/login-redirect": [71,[12]],
		"/(redirects)/membership": [~60],
		"/(root)/members": [72,[12,14]],
		"/(root)/members/[member]": [73,[12,14,15]],
		"/(admin my)/my": [~25,[2]],
		"/(admin my)/my/favorites": [~26,[2]],
		"/(admin my)/my/member-sharing": [27,[2]],
		"/(admin my)/my/member-sharing/[eventId]": [28,[3]],
		"/(admin my)/my/network": [~29,[2,4]],
		"/(admin my)/my/network/members": [~30,[2,4]],
		"/(admin my)/my/network/sponsors": [~31,[2,4]],
		"/(admin my)/my/notification-preferences": [~32,[2]],
		"/(admin my)/my/profiles": [~33,[2,5]],
		"/(admin my)/my/profiles/emergency-contact": [~34,[2,5]],
		"/(admin my)/my/profiles/primary": [~35,[2,5]],
		"/(admin my)/my/profiles/public": [~36,[2,5]],
		"/(admin my)/my/profiles/shared": [~37,[2,5]],
		"/(admin my)/my/profiles/slack": [38,[2,5]],
		"/(admin my)/my/settings": [~39,[2,6]],
		"/(admin my)/my/settings/badges": [40,[2,6]],
		"/(admin my)/my/settings/membership": [~41,[2,6]],
		"/(admin my)/my/settings/order-history": [~42,[2,6]],
		"/(admin my)/my/settings/tickets": [~43,[2,6]],
		"/(admin my)/my/submissions": [~44,[2]],
		"/(redirects)/m/[member]": [~59],
		"/(root)/newsletter": [74,[12]],
		"/(root)/newsletter/verify-your-humanity": [75,[12]],
		"/(root)/newsletter/what-now": [~76,[12]],
		"/(redirects)/online": [~61],
		"/(that conferences)/online/[state]/[year]": [118,[24]],
		"/(that conferences)/online/[state]/[year]/attendees": [~119,[24]],
		"/(that conferences)/online/[state]/[year]/schedule": [~120,[24]],
		"/(that conferences)/online/[state]/[year]/speakers": [~121,[24]],
		"/(that conferences)/online/[state]/[year]/sponsors": [~122,[24]],
		"/(root)/orders/canceled": [79,[12,16]],
		"/(root)/orders/(orders)/claim": [77,[12,16,17]],
		"/(root)/orders/success": [80,[12,16,18]],
		"/(root)/orders/success/claim-ticket": [81,[12,16,18]],
		"/(root)/orders/success/expo-hall-ticket": [82,[12,16,18]],
		"/(root)/orders/(orders)/summary": [78,[12,16,17]],
		"/(redirects)/partners/[partner]": [~62],
		"/(event support)/signs": [~55],
		"/(root)/signup": [83,[12]],
		"/(root)/speakers/accept": [~84,[12,19]],
		"/(root)/speakers/declined": [85,[12,19]],
		"/(admin sponsor)/sponsor-admin/leads": [45,[7]],
		"/(admin sponsor)/sponsor-admin/leads/[eventId]": [46,[7]],
		"/(admin sponsor)/sponsor-admin/my-network": [~47,[8]],
		"/(root)/sponsorships/apply": [92,[12,21]],
		"/(root)/sponsorships/prospectus": [93,[12,21]],
		"/(root)/sponsors": [86,[12,20]],
		"/(root)/sponsors/job-board": [~90,[12,20]],
		"/(root)/sponsors/past": [~91,[12,20]],
		"/(root)/sponsors/[partner]": [87,[12,20]],
		"/(root)/sponsors/[partner]/jobs/[job]": [~88,[12,20]],
		"/(root)/sponsors/[state]/[year]": [89,[12,20]],
		"/(root)/support": [94,[12,22]],
		"/(root)/support/anti-harassment": [95,[12,22]],
		"/(root)/support/code-of-conduct": [96,[12,22]],
		"/(root)/support/commitment-to-diversity": [97,[12,22]],
		"/(root)/support/contact-us": [98,[12,22]],
		"/(root)/support/convince-your-boss": [99,[12,22]],
		"/(root)/support/copyright": [100,[12,22]],
		"/(root)/support/covid-policies": [101,[12,22]],
		"/(root)/support/creating-an-activity": [102,[12,22]],
		"/(root)/support/faq": [103,[12,22]],
		"/(root)/support/joining-an-activity": [104,[12,22]],
		"/(root)/support/my-favorites-icalendar": [105,[12,22]],
		"/(root)/support/privacy-policy": [106,[12,22]],
		"/(root)/support/sponsors/expo-hall": [107,[12,22]],
		"/(root)/support/sponsors/forms": [108,[12,22]],
		"/(root)/support/staying-up-to-date": [109,[12,22]],
		"/(root)/support/terms-of-use": [110,[12,22]],
		"/(root)/support/that-field-guide": [111,[12,22]],
		"/(root)/support/transfer-a-ticket": [112,[12,22]],
		"/(root)/support/travel": [113,[12,22]],
		"/(root)/support/travel/tx": [114,[12,22]],
		"/(root)/support/travel/wi": [115,[12,22]],
		"/(root)/support/what-is-an-activity": [116,[12,22]],
		"/(redirects)/tx": [~63],
		"/(that conferences)/tx/[year]": [~123,[23]],
		"/(that conferences)/tx/[year]/attendees": [~124,[23]],
		"/(that conferences)/tx/[year]/schedule": [~125,[23]],
		"/(that conferences)/tx/[year]/speakers": [~126,[23]],
		"/(that conferences)/tx/[year]/sponsors": [~127,[23]],
		"/(that conferences)/tx/[year]/tickets": [128,[23]],
		"/(that conferences)/tx/[year]/travel": [129,[23]],
		"/(root)/verify-account": [117,[12]],
		"/(redirects)/wi": [~64],
		"/(that conferences)/wi/[year]": [~130,[23]],
		"/(that conferences)/wi/[year]/attendees": [~131,[23]],
		"/(that conferences)/wi/[year]/schedule": [~132,[23]],
		"/(that conferences)/wi/[year]/speakers": [~133,[23]],
		"/(that conferences)/wi/[year]/sponsors": [~134,[23]],
		"/(that conferences)/wi/[year]/tickets": [135,[23]],
		"/(that conferences)/wi/[year]/travel": [~136,[23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';